import axios from "axios";
import store from "../redux/store"
const TOKEN_NAME = "comis-app-token";


 axios.interceptors.response.use(
  response => response,
  error => {
      if (error.response.status === 401) {
        localStorage.removeItem(TOKEN_NAME);
        store.dispatch({ type: "USER_LOGOUT" })
      }
      
  });

  export default axios;